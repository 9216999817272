<template>
  <el-card
    shadow="never"
    class="aui-card--fill"
  >
    <div class="mod-sys__menu">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <div class="flex-between">
          <div class="flex-start" style="opacity: 0">
            <el-form-item>
              <el-input
                v-model="dataForm.searchStr"
                placeholder="搜索关键字"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="getDataList()"
              >
                搜索
              </el-button>
            </el-form-item>
          </div>
          <div class="flex-end">
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="addOrUpdateHandle(1)"
              >
                新增
              </el-button>
            </el-form-item>
<!--            <el-form-item>-->
<!--              <el-button-->
<!--                size="small"-->
<!--                type="danger"-->
<!--                @click="deleteHandle()"-->
<!--              >-->
<!--                删除-->
<!--              </el-button>-->
<!--            </el-form-item>-->
          </div>
        </div>
      </el-form>
      <el-table
          class="tree-table"
        v-loading="loading"
        :tree-props="{children: 'children'}"
        :data="dataList"
        row-key="id"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="label"
          header-align="left"
          label="类型名称"
        />
        <el-table-column
          prop="sortNo"
          width="50"
          label="排序号"
        />
        <el-table-column
          label="操作"
          fixed="right"
          width="150"
        >
          <template v-slot="scope">
            <el-link
              :underline="false"
              class="mr10"
              type="primary"
              @click="addOrUpdateHandle(2,scope.row)"
            >
              <el-tooltip
                content="新增子类"
                placement="top"
                effect="light"
              >
                <i class="el-icon-plus" />
              </el-tooltip>
            </el-link>
            <el-link
              :underline="false"
              class="mr10"
              type="primary"
              @click="addOrUpdateHandle(3,scope.row)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              :underline="false"
              type="primary"
              class="mr10"
              @click="deleteHandle(scope.row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <!--  编辑弹框    -->
      <edit-dialog
        ref="editDialog"
        @handleFilter="getDataList"
      />
    </div>
  </el-card>
</template>

<script>
import { GetRiskTypeTree, DeleteRiskTypeByIds } from '@/api/assess/risk'
import EditDialog from './category-edit.vue'
import {QuestionnaireCDelete} from "@/api/assess/questionTemplate";
import {error, success} from "@core/utils/utils";

export default {
  components: {
    EditDialog,
  },
  data() {
    return {
      dataList: [],
      loading: false,
      dataForm: {
        searchStr: '',
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    addOrUpdateHandle(type, row) {
      this.$refs.editDialog.visible = true
      this.$refs.editDialog.saveType = type
      // 新增子类
      if (type === 2) {
        this.$refs.editDialog.dataForm.id = 0
        this.$refs.editDialog.dataForm.parentId = row.id
      }
      // 修改
      if (type === 3) {
        this.$refs.editDialog.dataForm.id = row.id
      }
    },
    deleteHandle(id) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const ids = [id]
        DeleteRiskTypeByIds({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(res.data.msg)
            this.getDataList()
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    getDataList() {
      this.loading = true
      GetRiskTypeTree().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.dataList = resData.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style>
.tree-table .el-table__cell:first-child .cell {
  text-align: left;
}
</style>
