<template>
  <el-dialog
    :visible.sync="visible"
    :title="dataForm.id ? '修改' : '添加'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      label-width="120px"
      @keyup.enter.native="dataFormSubmitHandle()"
    >
      <el-form-item
        prop="riskTypeName"
        label="类型名称"
        :rules="[ { required: true, message: '类型名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="dataForm.riskTypeName"
          placeholder="类型名称"
        />
      </el-form-item>
      <el-form-item
        prop="parentId"
        label="父类型"
        class="dept-list"
      >
        <SelectTree
          v-model="dataForm.parentId"
          :disabled="saveType !== 1"
          :props="treeSelectProps"
          :options="treeOptions"
        />
      </el-form-item>
      <el-form-item
        prop="sortNo"
        label="排序号"
      >
        <el-input-number
          v-model="dataForm.sortNo"
          controls-position="right"
          :min="0"
          label="排序号"
        />
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="dataFormSubmitHandle()"
      >
        提 交
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import SelectTree from '@core/components/select-tree/SelectTree.vue'
import { GetRiskTypeTree, GetRiskType, saveOrUpdateRiskType } from '@/api/assess/risk'
import { error, success } from '@core/utils/utils'

export default {
  components: { SelectTree },
  data() {
    return {
      saveType: 1,
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'label', // 显示名称
        children: 'children', // 子级字段名
      },
      treeOptions: [],
      visible: false,
      dataForm: {
        id: 0,
        riskTypeName: '',
        parentId: 0,
        sortNo: 0,
      },
    }
  },
  methods: {
    dialogOpen() {
      this.getCategoryList()
      if (this.dataForm.id) {
        this.getInfo()
      }
    },
    dialogClose() {
      this.dataForm = {
        id: 0,
        riskTypeName: '',
        parentId: 0,
        sortNo: 0,
      }
      this.$refs.dataForm.resetFields()
    },
    // 获取信息
    getInfo() {
      GetRiskType({ id: this.dataForm.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.dataForm = resData.data
        }
      })
    },
    getCategoryList() {
      GetRiskTypeTree().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.treeOptions = resData.data
        }
      })
    },
    dataFormSubmitHandle() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateRiskType(this.dataForm).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.visible = false
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
