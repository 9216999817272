import { post, get } from '@/libs/axios'

export const saveOrUpdateRisk = params => {
  const submitUrl = params.id ? '/risk/UpdateRisk' : '/risk/CreateRisk'
  return post(submitUrl, params)
}
export const GetDetailInfo = id => post('/risk/GetDetailInfo', id)
export const GetRiskType = params => post('/questionnaire/riskType/GetRiskType', params)
export const DeleteRiskTypeByIds = params => post('/questionnaire/riskType/DeleteRiskTypeByIds', params)
export const GetRiskMatchInfo = params => post('/questionnaire/risk/match/Info', params)
export const GetRiskTypeOptions = () => get('/questionnaire/riskType/GetRiskTypeOptions')
export const GetRiskTypeTree = () => get('/questionnaire/riskType/GetRiskTypeTree')
//
export const saveOrUpdateRiskType = params => {
  const submitUrl = params.id ? '/questionnaire/riskType/UpdateRiskType' : '/questionnaire/riskType/CreateRiskType'
  return post(submitUrl, params)
}
export const saveOrUpdateMatchRisk = params => {
  const submitUrl = params.id ? '/questionnaire/risk/match/Update' : '/questionnaire/risk/match/Create'
  return post(submitUrl, params)
}
